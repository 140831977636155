<script>
import { mapActions } from 'vuex';
import Stripe from './components/Stripe.vue';
import Manual from './components/Manual.vue';

export default {
  name: 'CheckoutPayment',

  data() {
    return {
      ref: null,
      choosenMethod: null,
      methods: [
        {
          label: 'Bankkort',
          component: Stripe,
        },
        {
          label: 'Manuell hantering',
          component: Manual,
        },
      ],
    };
  },

  created() {
    this.ref = this.$route.params.ref;
  },

  methods: {
    ...mapActions('cart', ['empty']),

    gotoConfirmation() {
      this.empty();
      this.$router.push({
        name: 'orderConfirmation',
        params: { ref: this.ref },
      });
    },
  },

  components: { Stripe, Manual },
   mounted() {
    document.title = "Betalning";
  },
};
</script>

<template>
  <div class="flex flex-col">
    <div class="my-6 mx-auto w-full max-w-xl text-left">
      <p class="text-4xl">Betalning</p>
      <p>
        Du kan betala direkt med bankkort. Vi skickar de köpta bilderna så
        snart vår personal har mottagit beställningen. Om du vill betala på
        annat sätt väljer du "Manuell hantering", så kommer vi att kontakta dig rörande
        betalning.
      </p>
    </div>

    <div class="bg-white mx-auto w-full max-w-xl shadow-md flex mb-4">
      <button
        class="flex-1 py-4"
        :class="[
          {'bg-blue-500': choosenMethod === method },
          choosenMethod === method ? 'text-white' : 'text-blue-500'
        ]"
        v-for="(method, i) in methods"
        :key="i"
        @click="choosenMethod = method"
      >
        {{ method.label }}
      </button>
    </div>

    <div v-if="choosenMethod" class="bg-white mx-auto w-full max-w-xl shadow-md flex mb-4">
      <component
        :is="choosenMethod.component"
        :payment-ref="ref"
        @onCompleted="gotoConfirmation"
      />
    </div>
  </div>
</template>
