<script>
import orderService from '@/services/order';

export default {
  name: 'ManualPayment',

  props: {
    paymentRef: {
      type: String,
      required: true,
    },
  },

  methods: {
    async submit() {
      try {
        await orderService.payManually(this.paymentRef);
        this.$emit('onCompleted');
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<template>
  <div class="w-full p-4">
    {{ $t("checkout.manualText") }} <br>
    <button
      @click="submit"
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-6"
    >
      {{ $t("checkout.confirm") }}
    </button>
  </div>
</template>
