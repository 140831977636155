<script>
import orderService from '@/services/order';



const stripe = Stripe('pk_test_51OcaacLPWF14f1VmKIN56qPqJqcwirOyvu44epdA8rb1ulZpoT34FVtZr6dMojYUJI8NdfG4ZrL9Z9HhwGyPWDyL00y3ijKvvL');
const elements = stripe.elements();

export default {
  name: 'StripePayment',

  data() {
    return {
      orderRef: null,
      isLoading: false,
      card: null,
      errorMessage: null,
      showError: false,
      intent: null,
    };
  },

  async created() {
    this.orderRef = this.$route.params.ref;

    this.isLoading = true;

    this.intent = await orderService.findOrCreateIntent(this.orderRef);

    if (this.intent.status === 'succeeded') {
      this.$emit('onCompleted');
    }

    this.isLoading = false;
     this.customer = JSON.parse(localStorage.getItem("customer"));

     console.log(this.customer, "customer")
     console.log(this.intent, "intent")

 
  },

  

  mounted() {
    const style = {
      base: {
        color: '#32325d',
      },
    };

   

    if (!this.card) {
      this.card = elements.create('card', { style });
      this.card.mount(this.$refs['card-element']);
    }
  },

  destroyed() {
    this.card.destroy();
  },

  methods: {
    async submit() {
      this.isLoading = true;
      const paymentMethod = {
        card: this.card,
      };

      const res = await stripe.confirmCardPayment(this.intent.client_secret, {
        payment_method: paymentMethod,
      });

      if (res.error) {
        this.handleError(res.error);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (res.paymentIntent.status === 'succeeded') {
          this.$emit('onCompleted');
        } else {
          console.log('weird status', res);
        }
      }

      this.isLoading = false;
    },
    

    amount(val) {
      const amount = val;
      const currency = this.intent.currency.toUpperCase();
      return `${amount} ${currency}`;
    },

    async handleError(error) {
      console.error(error);
      this.showError = true;
      this.errorMessage = error.message;
    },

    async resetError() {
      this.showError = false;
      this.errorMessage = null;
    },
  },
};


</script>


<template>
  <div class="w-full p-4">
    <form id="payment-form" @submit.prevent="submit">
      <div
        id="card-element"
        ref="card-element"
        class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white mb-6"
      >
        <!-- Elements will create input elements here -->
      </div>

      <!-- We'll put the error messages in this element -->
      <div
        id="card-errors"
        role="alert"
        class="bg-red-100 text-left border border-red-400 text-red-700 px-4 py-3 rounded relative"
        v-if="showError && errorMessage"
      >
        {{ errorMessage }}
      </div>


      <div class="p-4 bg-gray-200 rounded-md" v-if="intent">
        <div class="flex justify-between">
          <span>{{ $t('checkout.subtotal') }}: </span>
          <span>{{ amount(intent.metadata.excluding_vat) }}</span>
        </div>
        {{ customer.id }}
        <div class="flex justify-between" v-if="intent.metadata.delivery_price > 0">
          <span>{{ $t('checkout.deliveryStipe') }}:</span>
          <span> {{ amount(intent.metadata.delivery_price) }}</span>
        </div>
<!-- 
        <div class="flex justify-between" v-if="this.customer.country.is_eu === 0 && this.customer.type != 'company' ">
            <span>{{ $t('checkout.delivery') }}:</span>
            <span> 80  {{ (intent.currency).toUpperCase() }}    </span> 
          </div> -->

          <!-- Type 1 -->
            <div class="flex justify-between"  v-if="this.customer.type === 'company' && this.customer.country.code == 'SE'">
              <span>{{ $t('checkout.vat') }} (25%)</span>
             <!-- {{ (intent.metadata.excluding_vat / 100) * 0.25 }}    -->
             {{ intent.metadata.applied_vat }} {{ (intent.currency).toUpperCase() }}    
             
               
            </div>

            <!-- Type 2 -->
            <div class="flex justify-between" v-else-if="this.customer.type === 'company' && this.customer.orgnr && this.customer.country.is_eu === 1">
              <span>{{ $t('checkout.vat') }} (0%)</span>
               0   {{ (intent.currency).toUpperCase() }}    
            </div>

            <!-- Type 3 -->
            <div class="flex justify-between" v-else-if="this.customer.type === 'company' && !this.customer.orgnr && this.customer.country.is_eu === 1">
              <span>{{ $t('checkout.vat') }} (25%)</span>

               {{ intent.metadata.applied_vat }}   {{ (intent.currency).toUpperCase() }}    
            </div>
          
            <!-- Type 4 -->
            <div class="flex justify-between" v-else-if="this.customer.type === 'company' && this.customer.country.is_eu === 0">
              <span>{{ $t('checkout.vat') }} (0%)</span>
              0   {{ (intent.currency).toUpperCase() }}    
            </div>

            <!-- Type 5 -->
            <div class="flex justify-between" v-else-if="this.customer.type === 'personal' && this.customer.country.code == 'SE'">
              <span>{{ $t('checkout.vat') }} (25%)</span>
                  {{ intent.metadata.applied_vat }}   {{ (intent.currency).toUpperCase() }}    
            </div>
          
            <!-- Type 6 -->
            <div class="flex justify-between" v-else-if="this.customer.type === 'personal' && this.customer.country.is_eu === 1">
              <span>{{ $t('checkout.vat') }} ({{ this.customer.country.vat }}%)</span>
               {{ intent.metadata.applied_vat }}   {{ (intent.currency).toUpperCase() }}    
       
            </div>
     
            <!-- Type 7 -->
            <div class="flex justify-between" v-else-if="this.customer.type === 'personal' && this.customer.country.code == 'NO'">
                <span>{{ $t('checkout.vat') }} (0%)</span>
                0  {{ (intent.currency).toUpperCase() }}    
            </div>

            <!-- Type 8 -->
            <div class="flex justify-between" v-else-if="this.customer.type === 'personal' && this.customer.country.is_eu === 0">
              <span>{{ $t('checkout.vat') }} (0%)</span>
                0   {{ (intent.currency).toUpperCase() }}    
            </div>
        

             <!-- Type 1 -->
             <div class="flex justify-between" v-if="this.customer.type === 'company' && this.customer.country.code == 'SE'">
                <span>{{ $t('checkout.total') }}:</span>
                <span>{{ amount(intent.amount / 100 ) }}</span>
          
              </div>

               <!-- Type 2 -->
               <div class="flex justify-between" v-else-if="this.customer.type === 'company' && this.customer.orgnr && this.customer.country.is_eu === 1">
                  <span>{{ $t('checkout.total') }}:</span>
                  <span>{{ amount(intent.amount / 100 ) }}</span>
                </div>

                <!-- Type 3 -->
                 <div class="flex justify-between" v-else-if="this.customer.type === 'company' && !this.customer.orgnr && this.customer.country.is_eu === 1">
                <span>{{ $t('checkout.total') }}:</span>
                <span>{{ amount(intent.amount / 100 ) }}</span>
              </div>

               <!-- Type 4 -->
                <div class="flex justify-between" v-else-if="this.customer.type === 'company' && this.customer.country.is_eu === 0">
                    <span>{{ $t('checkout.total') }}:</span>
                    <span>{{ amount(intent.amount / 100) }}</span>
                  </div>

                   <!-- Type 5 -->
                    <div class="flex justify-between" v-else-if="this.customer.type === 'personal' && this.customer.country.code == 'SE'">
                <span>{{ $t('checkout.total') }}:</span>
                <span>{{ amount(intent.amount / 100 ) }}</span>
              </div>

              <!-- Type 6 -->
              <div class="flex justify-between" v-else-if="this.customer.type === 'personal' && this.customer.country.is_eu === 1">
                  <span>{{ $t('checkout.total') }}:</span>
                  <span>{{ amount(intent.amount / 100 ) }}</span>
                </div>

              <!-- Type 7 -->
              <div class="flex justify-between" v-else-if="this.customer.type === 'personal' && this.customer.country.code == 'NO'">
                      <span>{{ $t('checkout.total') }}:</span>
                      <span>{{ amount(intent.amount / 100) }}</span>
                    </div>

                    <!-- Type 8 -->
            <div class="flex justify-between" v-else-if="this.customer.type === 'personal' && this.customer.country.is_eu === 0">
                        <span>{{ $t('checkout.total') }}:</span>
                        <span>{{ amount(intent.amount / 100) }}</span>
                      </div>
      </div>

      <button
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-6"
        type="button"
        id="submit"
        @click="submit"
        :disabled="isLoading"
      >
        <template v-if="isLoading">
          <i class="fas fa-circle-notch fa-spin"></i>
        </template>
        <template v-else>{{ $t("checkout.pay") }}</template>
      </button>
    </form>
  </div>
</template>
